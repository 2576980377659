window.onload = function(){
  if (location.pathname == "/") {
    if (window.matchMedia('(max-width: 499px)').matches) {
      var options = {
        pageTopBtn : 'fixedBtn',
        showScroll : 1000,
        scrollSpeed : 10,
        fadeSpeed : 12
      };
    } else if (window.matchMedia('(max-width: 749px)').matches) {
      var options = {
        pageTopBtn : 'fixedBtn',
        showScroll : 1400,
        scrollSpeed : 10,
        fadeSpeed : 12
      };
    } else if (window.matchMedia('(max-width: 1023px)').matches) {
      var options = {
        pageTopBtn : 'fixedBtn',
        showScroll : 2000,
        scrollSpeed : 10,
        fadeSpeed : 12
      };
    } else if (window.matchMedia('(min-width: 1024px)').matches) {
      var options = {
        pageTopBtn : 'fixedBtn',
        showScroll : 1100,
        scrollSpeed : 10,
        fadeSpeed : 12
      };
    }

    var btn = document.getElementById(options.pageTopBtn) ;

    var fadeIn_timer;
    function fadeInTimer(opaValue){
        if (opaValue < 1){
            opaValue = opaValue + 0.05;
            btn.style.filter = "alpha(opacity:"+(opaValue*100)+")";
            btn.style.opacity = opaValue; 
            fadeIn_timer = setTimeout(function(){fadeInTimer(opaValue);}, options.fadeSpeed);
        } else {
            clearTimeout(fadeIn_timer);
            btn.style.filter = "alpha(opacity:100)";
            btn.style.opacity = 1;
        }
    }

    var fadeOut_timer;
    function fadeOutTimer(opaValue){
        if ( opaValue >= 0.05){
            opaValue = opaValue - 0.05;
            btn.style.filter = "alpha(opacity:"+(opaValue*100)+")";
            btn.style.opacity = opaValue; 
            fadeOut_timer = setTimeout(function(){ fadeOutTimer(opaValue); }, options.fadeSpeed);
        } else {
            clearTimeout(fadeIn_timer);
            btn.style.filter = "alpha(opacity:0)";
            btn.style.opacity = 0;
        }
    }

    btn.style.opacity = 0;
    btn.style.filter = "alpha(opacity:0)";
    window.onscroll = function(){
        var winSc = document.body.scrollTop || document.documentElement.scrollTop;
        if(winSc >= options.showScroll){
            clearTimeout(fadeOut_timer);
            var opaValue = parseFloat(btn.style.opacity);
            fadeInTimer(opaValue);
        } else {
            clearTimeout(fadeIn_timer);
            var opaValue = parseFloat(btn.style.opacity);
            fadeOutTimer(opaValue);
        }
    }
  }
}    
