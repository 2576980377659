const formbtn = document.getElementById("form-btn")
const agreebtn = document.getElementById('agree-btn')


if(agreebtn) {
  formbtn.disabled = false
  agreebtn.addEventListener('click',function(){
    if (this.checked) {
      formbtn.disabled = false
    } else {
      formbtn.disabled = true
    }
  })
}
