export function formValidation() {
  if (location.pathname == "/contact/") {
    // 会社名
    const company_name = document.getElementById('company-name');

    const company_name_error = document.getElementById('company-name-error');

    // const mutationObserver = new MutationObserver(callback);

    company_name.addEventListener('change', e => {
      if (e.target.value != "") {
        e.target.removeAttribute('required')
        e.target.classList.remove('error')
        company_name_error.classList.remove('error-show')
      } else {
        e.target.setAttribute('required', '')
        e.target.classList.add('error')
        company_name_error.classList.add('error-show')
      }
    })

    // 会社名かな
    const company_name_kana = document.getElementById('company-name-kana');

    const company_name_kana_error = document.getElementById('company-name-kana-error');

    company_name_kana.addEventListener('change', e => {
      if (e.target.value != "") {
        e.target.removeAttribute('required')
        e.target.classList.remove('error')
        company_name_kana_error.classList.remove('error-show')
      } else {
        e.target.setAttribute('required', '')
        e.target.classList.add('error')
        company_name_kana_error.classList.add('error-show')
      }
    })

    // 会社サイトURL
    const company_url = document.getElementById('company-url');

    const company_url_error = document.getElementById('company-url-error');

    company_url.addEventListener('change', e => {
      if (e.target.value != "") {
        e.target.removeAttribute('required')
        e.target.classList.remove('error')
        company_url_error.classList.remove('error-show')
      } else {
        e.target.setAttribute('required', '')
        e.target.classList.add('error')
        company_url_error.classList.add('error-show')
      }
    })

    // 担当者
    const person_name = document.getElementById('person-name');

    const person_name_error = document.getElementById('person-name-error');

    person_name.addEventListener('change', e => {
      if (e.target.value != "") {
        e.target.removeAttribute('required')
        e.target.classList.remove('error')
        person_name_error.classList.remove('error-show')
      } else {
        e.target.setAttribute('required', '')
        e.target.classList.add('error')
        person_name_error.classList.add('error-show')
      }
    })

    // 担当者かな
    const person_name_kana = document.getElementById('person-name-kana');

    const person_name_kana_error = document.getElementById('person-name-kana-error');

    person_name_kana.addEventListener('change', e => {
      if (e.target.value != "") {
        e.target.removeAttribute('required')
        e.target.classList.remove('error')
        person_name_kana_error.classList.remove('error-show')
      } else {
        e.target.setAttribute('required', '')
        e.target.classList.add('error')
        person_name_kana_error.classList.add('error-show')
      }
    })

    // 電話番号
    const tel = document.getElementById('tel');

    const tel_error = document.getElementById('tel-error');

    tel.addEventListener('change', e => {
      if (e.target.value != "") {
        e.target.removeAttribute('required')
        e.target.classList.remove('error')
        tel_error.classList.remove('error-show')
      } else {
        e.target.setAttribute('required', '')
        e.target.classList.add('error')
        tel_error.classList.add('error-show')
      }
    })

    // メールアドレス
    const email = document.getElementById('email');

    const email_error = document.getElementById('email-error');

    email.addEventListener('change', e => {
      if (e.target.value != "") {
        e.target.removeAttribute('required')
        e.target.classList.remove('error')
        email_error.classList.remove('error-show')
      } else {
        e.target.setAttribute('required', '')
        e.target.classList.add('error')
        email_error.classList.add('error-show')
      }
    })

    // 送信ボタンを押した際のバリデーション
    const form_btn = document.getElementById('form-btn');

    form_btn.addEventListener('click', e => {
      if (company_name.value == '') {
        company_name.classList.add('error')
        company_name_error.classList.add('error-show')
      }
      if (company_name_kana.value == '') {
        company_name_kana.classList.add('error')
        company_name_kana_error.classList.add('error-show')
      }
      if (company_url.value == '') {
        company_url.classList.add('error')
        company_url_error.classList.add('error-show')
      }
      if (person_name.value == '') {
        person_name.classList.add('error')
        person_name_error.classList.add('error-show')
      }
      if (person_name_kana.value == '') {
        person_name_kana.classList.add('error')
        person_name_kana_error.classList.add('error-show')
      }
      if (tel.value == '') {
        tel.classList.add('error')
        tel_error.classList.add('error-show')
      }
      if (email.value == '') {
        email.classList.add('error')
        email_error.classList.add('error-show')
      }
    })
  }
}
